import { IconButton, MenuItem, Popper, useTheme } from "@mui/material";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import { TRACK_EVENTS } from "core/consts";
import RSButton, {
  RSButtonColor,
  RSButtonVariant,
} from "ds_legacy/components/RSButton";
import {
  APP_BAR_PROVIDERSEARCH_HEIGHT,
  dp,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_16,
  FONT_SIZE_24,
} from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import Tabs, { Tab } from "dsl/atoms/Tabs";
import {
  PROVIDER_SEARCH_LOGIN_MODAL,
  PROVIDER_SEARCH_SIGNUP_MODAL,
} from "dsl/ecosystems/ProviderSearchLoginModal/shared";
import { useDropdown, useModal } from "dsl/hooks";
import useFocusWithin from "dsl/hooks/useFocusWithin";
import { useProvidersearchNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { RegistrationPopover } from "dsl/organisms/ProviderSearchRegistrationDropDown";
import { MenuIcon } from "lucide-react";
import { useEffect } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useLoggedInAccount } from "reduxentities/hooks";
import styled from "styled-components";
import { useTranslations } from "translations";
import { ConfirmLogoutDialog } from "./LogoutConfirmation";

type NavBarItem = {
  ariaProps?: Record<string, boolean | string>;
  buttonVariant?: RSButtonVariant;
  color?: RSButtonColor;
  isSelected?: boolean;
  key: string;
  onClick: (event?: any) => void;
  show: boolean;
  title: string;
  url?: string;
};

const useGetNavBarActions = ({
  isHamburgerMenu = false,
  onCloseRegistration,
  onOpenLogoutModal,
  onOpenRegistration,
  openRegistration,
}: {
  isHamburgerMenu?: boolean;
  onCloseRegistration?: () => void;
  onOpenLogoutModal: () => void;
  onOpenRegistration?: (event?: any) => void;
  openRegistration?: boolean;
}): NavBarItem[] => {
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const { goToAccount, goToGlossary, goToListing, goToSearchDashboard } =
    useProvidersearchNavigationUrls();
  const account = useLoggedInAccount();
  const { isLoggedIn, patients, setLoginModal } = useProviderSearchContext();

  const patientsAvailable =
    patients.active.concat(patients.archived).length > 0;
  const onboardingSelected = useMatch("providersearch/bavaria");

  return [
    {
      key: "listing",
      url: goToListing(),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_MAIN_MENU_HOMEPAGE_CLICKED,
        });
      },
      title: translations.providersearch.header.homePage,
      show: true,
      isSelected: !!onboardingSelected,
    },
    {
      key: "glossary",
      url: goToGlossary(),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_MAIN_MENU_GLOSSARY_CLICKED,
        });
      },
      title: translations.providersearch.header.glossary,
      show: true,
    },
    {
      key: "patient",
      url: goToSearchDashboard(),
      onClick: () => {
        trackEvent({
          name: TRACK_EVENTS.PROVIDER_SEARCH_MAIN_MENU_SEARCHES_CLICKED,
        });
      },
      title: translations.providersearch.header.requestDashboard,
      show: isLoggedIn && patientsAvailable,
    },
    {
      ariaProps: {
        "aria-haspopup": "dialog",
      },
      key: "login",
      onClick: () => {
        setLoginModal(PROVIDER_SEARCH_LOGIN_MODAL);
      },
      title: translations.providersearch.loginDropDown.loginButton,
      show: !isLoggedIn && isHamburgerMenu,
    },
    {
      ariaProps: {
        "aria-haspopup": "dialog",
      },
      key: "createAccount",
      onClick: () => {
        setLoginModal(PROVIDER_SEARCH_SIGNUP_MODAL);
      },
      title: translations.providersearch.loginDropDown.createAccountButton,
      show: !isLoggedIn && isHamburgerMenu,
    },
    {
      key: "account",
      url: account?.id ? goToAccount({ accountId: account.id }) : "",
      onClick: () => {
        if (account?.id) {
          trackEvent({
            name: TRACK_EVENTS.PROVIDER_SEARCH_MAIN_MENU_MY_ACCOUNT_CLICKED,
          });
        }
      },
      title: translations.providersearch.header.myAccount,
      show: isLoggedIn,
    },
    {
      ariaProps: {
        "aria-haspopup": "dialog",
      },
      key: "register",
      onClick: (e) => {
        openRegistration ? onCloseRegistration?.() : onOpenRegistration?.(e);
      },
      buttonVariant: "contained",
      color: "primary",
      title: translations.providersearch.header.register,
      show: !isLoggedIn && !isHamburgerMenu,
    },
    {
      ariaProps: {
        "aria-haspopup": "dialog",
      },
      key: "logout",
      onClick: () => {
        onOpenLogoutModal();
      },
      title: translations.providersearch.header.logout,
      buttonVariant: "outlined",
      color: "primary",
      show: isLoggedIn,
    },
  ];
};

const StyledListItem = styled.li`
  display: flex;
  flex: 1 1 auto;
`;

function NavigationActions({
  onOpenLogoutModal,
}: {
  onOpenLogoutModal: () => void;
}) {
  const { anchor, onClose, onOpen, open } = useDropdown();

  const actionItems = useGetNavBarActions({
    onCloseRegistration: onClose,
    onOpenRegistration: onOpen,
    openRegistration: open,
    onOpenLogoutModal,
  });

  return (
    <>
      <Tabs
        containerAs="ul"
        containerStyle={{ margin: 0, padding: 0, listStyleType: "none" }}
        fit
        height={dp(APP_BAR_PROVIDERSEARCH_HEIGHT)}
        tabsContainerPadding={padding(1 / 8, 0, 0, 0)}
      >
        {actionItems
          .filter((item) => item.show && item.url)
          .map((itemConfig) => {
            return (
              <StyledListItem key={itemConfig.key}>
                <Tab
                  disableTouchRipple
                  testId={itemConfig.key}
                  fontSize={FONT_SIZE_16}
                  label={itemConfig.title}
                  match={itemConfig.key}
                  onClick={itemConfig.onClick}
                  primary
                  tabMargin={margin(0, 1.5)}
                  uppercase={false}
                  url={itemConfig.url}
                  isSelected={itemConfig.isSelected}
                />
              </StyledListItem>
            );
          })}
      </Tabs>
      {actionItems
        .filter((item) => item.show && !item.url)
        .map((itemConfig) => {
          return (
            <RSButton
              id={itemConfig.key}
              key={itemConfig.key}
              color={itemConfig.color}
              onClick={itemConfig.onClick}
              loading="na"
              variant={itemConfig.buttonVariant ?? "text"}
              ariaProps={itemConfig.ariaProps}
            >
              {itemConfig.title}
            </RSButton>
          );
        })}

      <RegistrationPopover anchor={anchor} onClose={onClose} open={open} />
    </>
  );
}

const OverlayList = styled.ul`
  background-color: #fff;
  border-radius: ${dp(4)};
  box-shadow:
    ${dp(0)} ${dp(5)} ${dp(5)} ${dp(-3)} rgba(0, 0, 0, 0.2),
    ${dp(0)} ${dp(8)} ${dp(10)} ${dp(1)} rgba(0, 0, 0, 0.14),
    ${dp(0)} ${dp(3)} ${dp(14)} ${dp(2)} rgba(0, 0, 0, 0.12);
`;

const menuItemStyles = `
  border: 0;
  margin: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  padding: ${padding(0.75, 2)};
`;

const MenuLink = styled(Link)`
  ${menuItemStyles}
  text-decoration: none;
`;

const MenuButton = styled.button`
  ${menuItemStyles}
  background-color: transparent;
  text-align: left;
  cursor: pointer;
`;

const MenuLabel = ({ label }: { label: string }) => (
  <Caption fontSize={FONT_SIZE_16} lineHeight={FONT_SIZE_24} margin={margin(0)}>
    {label}
  </Caption>
);

function DropdownMenu({
  anchor,
  id,
  onClose,
  onOpenLogoutModal,
  open,
}: {
  anchor: Element | null;
  id: string;
  onClose: () => void;
  onOpenLogoutModal: () => void;
  open: boolean;
}) {
  const menuItems = useGetNavBarActions({
    onOpenLogoutModal,
    isHamburgerMenu: true,
  });

  const location = useLocation();

  return (
    <Popper
      disablePortal
      anchorEl={anchor}
      open={open}
      id={id}
      placement="bottom-end"
      components={{ Root: OverlayList }}
      componentsProps={{ root: { role: undefined } }}
      sx={{ padding: padding(1, 0) }}
    >
      {menuItems
        .filter((item) => item.show)
        .map((item) => {
          const selected = location.pathname.search(new RegExp(item.key)) >= 0;

          const handleClick = () => {
            item.onClick();
            onClose();
          };

          return (
            <MenuItem
              key={item.key}
              data-testid={item.key}
              role="listitem"
              sx={{ padding: 0 }}
              selected={selected}
            >
              {item.url ? (
                <MenuLink
                  aria-current={selected ? "page" : undefined}
                  onClick={handleClick}
                  to={item.url}
                >
                  <MenuLabel label={item.title} />
                </MenuLink>
              ) : (
                <MenuButton onClick={handleClick} {...item.ariaProps}>
                  <MenuLabel label={item.title} />
                </MenuButton>
              )}
            </MenuItem>
          );
        })}
    </Popper>
  );
}

function HamburgerMenu({
  onOpenLogoutModal,
}: {
  onOpenLogoutModal: () => void;
}) {
  const translations = useTranslations();
  const theme = useTheme();
  const { anchor, onClose, onOpen, open } = useDropdown();
  const { isFocusWithin, parentRef } = useFocusWithin<HTMLSpanElement>();

  useEffect(() => {
    if (!isFocusWithin && open) {
      onClose();
    }
  }, [isFocusWithin, open]);

  const menuId = "hamburger-navigation-menu";

  return (
    <span ref={parentRef}>
      <IconButton
        aria-controls={open ? menuId : undefined}
        data-testid="hamburger"
        onClick={open ? onClose : onOpen}
        aria-label={
          translations.providersearch.searchDashboard.accessibility.menuLabel
        }
        aria-expanded={open}
      >
        <MenuIcon
          style={{ color: theme.palette.neutrals.base_dark }}
          size={FONT_SIZE_16}
        />
      </IconButton>
      <DropdownMenu
        id={menuId}
        onOpenLogoutModal={onOpenLogoutModal}
        anchor={anchor}
        open={open}
        onClose={onClose}
      />
    </span>
  );
}

export const NavBarActions = () => {
  const { isTablet } = useMedia();
  const [logoutModalOpen, setLogoutModalOpen] = useModal();

  return (
    <>
      {isTablet ? (
        <HamburgerMenu onOpenLogoutModal={() => setLogoutModalOpen(true)} />
      ) : (
        <NavigationActions onOpenLogoutModal={() => setLogoutModalOpen(true)} />
      )}
      {logoutModalOpen && (
        <ConfirmLogoutDialog onClose={() => setLogoutModalOpen(false)} />
      )}
    </>
  );
};
