import {
  useCreateEncryptionContext,
  useInitiateSealdIdentity,
} from "apollo/hooks/mutations";
import RootPage from "containers/RootPage";
import {
  ProviderSearchContext,
  ProviderSearchProvider,
  TestProviderSearchProvider,
  useProviderSearchContext,
} from "context/ProviderSearchContext";
import {
  APP_VERSION_PROVIDER_SEARCH,
  PROVIDER_SEARCH_DASHBOARD_FILTERS,
} from "core/consts";
import api from "core/model/api";
import { useSealdContext } from "core/seald/SealdContext";
import { APP_BAR_PROVIDERSEARCH_HEIGHT, dp } from "ds_legacy/materials/metrics";
import { useModal, useScrollToTopOnPathChange } from "dsl/hooks";
import { initialStateProviderSearch } from "dsl/organisms/Filters/ProviderSearchApp/initialState";
import {
  ProviderSearchFilterActions,
  ProviderSearchFilters,
} from "dsl/organisms/Filters/ProviderSearchApp/types";
import { persistReducerFunction } from "dsl/organisms/Filters/utils/persistFilterReducer";
import { useEffect } from "react";
import { Outlet, Route, Routes, useOutletContext } from "react-router-dom";
import {
  useConnecterWithLoggedAccount,
  useLoggedCareseeker,
} from "reduxentities/hooks";
import styled from "styled-components";
import Translations from "translations/types";
import { ProviderSearchLoginModal } from "../../ecosystems/ProviderSearchLoginModal";
import { CONTENT_ID } from "../A11ySkipLink";
import { ProviderSearchEnvSwitch } from "./EnvSwitcher";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { usePersistQueryParamProviderSearch } from "./usePersistProviderSearchFilters";

const Page = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  max-width: 100%;

  @media print {
    width: 100%;
    min-height: unset;
  }
`;

export const AppBarWrapper = styled.main`
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  min-height: calc(100vh - ${dp(APP_BAR_PROVIDERSEARCH_HEIGHT)});
`;

export function ProviderSearchRootPage() {
  return (
    <ProviderSearchProvider>
      <RootPage appName={APP_VERSION_PROVIDER_SEARCH}>
        <Page>
          <Outlet />
        </Page>
      </RootPage>
    </ProviderSearchProvider>
  );
}

export type ProviderSearchFilterContext = {
  filters: ProviderSearchFilters;
  filtersDrawerOpen: boolean;
  setFilters: React.Dispatch<ProviderSearchFilterActions>;
  setFiltersDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useProviderSearchFilterContext() {
  return useOutletContext<ProviderSearchFilterContext>();
}

export function getProviderSearchTitle(
  translations: Translations,
  title: string,
) {
  return `${translations.providersearch.header.title} | ${title}`;
}

export function ProviderSearchHome() {
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useModal();
  const account = useConnecterWithLoggedAccount();
  const careseeker = useLoggedCareseeker();
  const [createEncryptionContext] = useCreateEncryptionContext();
  const [initiateSealdIdentity] = useInitiateSealdIdentity();
  const createSealdGroup = useSealdContext()?.createSealdGroup;

  useScrollToTopOnPathChange();

  useEffect(() => {
    if (account && careseeker) {
      if (createSealdGroup) {
        createSealdGroup({
          entityType: "careseeker",
          entity: careseeker,
          entityAccounts: [account],
          createEncryptionContext,
          initiateSealdIdentity,
          createSealdAccess: api.crypto.createSealdAccess,
          loggedAccount: account,
        });
      } else {
        console.error(
          "could not create seald group in ProviderSearchHome as createSealdGroup not initialised",
        );
      }
    }
  }, [account?.id, careseeker?.id]);

  return (
    <>
      <ProviderSearchEnvSwitch />
      <Header filtersDrawerOpen={filtersDrawerOpen} />
      <AppBarWrapper id={CONTENT_ID}>
        <Outlet
          context={{
            filtersDrawerOpen,
            setFiltersDrawerOpen,
          }}
        />
        <ProviderSearchLoginModal />
      </AppBarWrapper>
      <Footer />
    </>
  );
}

export function ProviderSearchFiltersWrapper() {
  const context = useProviderSearchContext();
  const filterContext = useProviderSearchFilterContext();
  const [filters, setFilters] = usePersistQueryParamProviderSearch(
    PROVIDER_SEARCH_DASHBOARD_FILTERS,
    persistReducerFunction,
    initialStateProviderSearch,
    context.config.allowedFederalState,
  );

  return (
    <Outlet
      context={{
        ...filterContext,
        filters,
        setFilters,
      }}
    />
  );
}

export const TestProviderSearchWrapper = ({
  children,
  providerSearchContext,
  providerSearchFilterContext,
}: {
  children: React.ReactNode;
  providerSearchContext?: Partial<ProviderSearchContext>;
  providerSearchFilterContext?: ProviderSearchFilterContext;
}) => {
  if (!providerSearchFilterContext && !providerSearchContext) {
    return <>{children}</>;
  }
  return (
    <TestProviderSearchProvider providerSearchContext={providerSearchContext}>
      <Routes>
        <Route
          path="/"
          element={<Outlet context={providerSearchFilterContext} />}
        >
          <Route index element={children} />
        </Route>
      </Routes>
    </TestProviderSearchProvider>
  );
};
